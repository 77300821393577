import CarinaProvider from '@cvent/carina/components/CarinaProvider';
import App, {AppContext, AppProps} from 'next/app';
import Head from 'next/head';
import {Fragment, JSX, useEffect} from 'react';
import {initializeAnalyticsUser} from '../lib/analytics';
import {getExtendedRequest} from '../lib/get-extended-request';
import {isServerSide} from '../lib/is-server-side';

import '@cvent/carina/tailwind/preflight.css'; // <-- Tailwind reset
import '@cvent/carina/tailwind/tailwind.css'; // <-- Utility and component styles
// eslint-disable-next-line import/extensions
import '@cvent/carina-dsm/css/carina-theme.css'; // CSS variable tokens
import '@cvent/carina/tailwind/fonts.css'; // Cvent fonts
// Add global styles last to ensure they have higher precedence for equal specificity rules
import '../styles/global.css';

type CustomAppProps = AppProps & {
  referrer: string;
  user?: StUser;
};

const CustomApp = ({
  Component,
  pageProps,
  referrer,
  user,
}: CustomAppProps): JSX.Element => {
  // Use effect guarantees the window object will exist (which DD attaches to)
  useEffect(() => {
    initializeAnalyticsUser(user, referrer);
  }, [referrer, user]);

  return (
    <Fragment>
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      <CarinaProvider>
        <Component {...pageProps} />
      </CarinaProvider>
    </Fragment>
  );
};

CustomApp.getInitialProps = async ({ctx, ...rest}: AppContext) => {
  let user, referrer;

  if (isServerSide() && ctx.req) {
    const {state} = getExtendedRequest(ctx.req);

    user = state.session.user;
    referrer = state.referrer;
  }

  // calls App's `getInitialProps` and forward to page's initial props
  const appProps = await App.getInitialProps({ctx, ...rest});

  return {...appProps, referrer, user};
};

export default CustomApp;
